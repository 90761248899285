@import '../../base/settings';
.PwdEyeBox {
    background-color: $white;
    color: $dark-grey;
}

.PwdEyeBox__icon {
    width: 20px; // Necesario porque es el ancho del slashed eye
}

.PwdEyeBox--error {
    background-color: $red;
    border: 1px solid $red;
    color: $white;
}

.PwdCriteria--ok {
    text-decoration: line-through;
    color: $primary
}

.PwdCriteria--error {
    color: $red;
    line-height:15px;
}


.ActionButton {
    &:hover, &:visited, &:focus, &:focus-visible {
        color: $primary;
        border: 2px solid $primary;
    }
    &:focus-visible, &:focus  {
        outline: 0; 
    }
    background-color: $white;
    color: $primary;
    border-radius: 25px;
    border: 2px solid $primary;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
    display: inline-block;
}

.ActionButton--primary {
    &:hover, &:visited {
        color: $white;
    }
    background-color: $primary;
    color: $white;
}
.ActionButton--secondary {
    &:hover, &:visited {
        color: $primary;
    }
    background-color: $white;
    color: $primary;
    margin-top: 10px;
    width: 100%;
    border-radius: 99px;
    height: 42px;
    font-weight: 600;
    font-size: 16px;
    line-height: 21.79px;
    text-align: center;
}

.icon {
    color:#a5017c;
}
.icon--ok {
    color:$green;
}
.icon--error {
    color: $red;
}
.icon--info {
    color: $blue;
}
.icon--warning {
    color: $yellow;
}

.AcceptDialog__title {
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 1em;
}

.AcceptDialog__icon {
    text-align: center;
    margin-top: 1.2em;
}
.AcceptDialog__container {
    padding: 10px;
    border-radius: 15px !important;
    max-width: 550px !important;
}

.AcceptDialog__body {
    color: $black;
    padding: 0.5em 1em;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
}

.AcceptDialog__button {
    text-align: center;
    margin-bottom: 1em;
    margin-top: 1em;
    *.ActionButton {
        width: 70%;
    }
    *.ActionButton--w95 {
        width: 95%;
    }
}
.AcceptDialog__image {
  max-width: 500px;
  height: 160px;
  margin-left: 14px;
  text-align: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), linear-gradient(0deg, #000000, #000000), url("../../../assets/images/welcome-eanuser.jpg");
  background-blend-mode: normal, color, normal;
  border-radius: 8px;
  background-size: cover;
  background-position: bottom;
}
.AcceptDialog__message {
  color: $black;
  padding: 0.5em 1em;
  font-weight: 400;
  font-size: 1em;
  text-align: center;
}
.AcceptDialog__link {
  color: $primary;
  font-weight: bold;
}
.AcceptDialog__link:hover {
  text-decoration: underline;
  color: #3BAC53;
}
.full-width{
  width: 900px !important;
}

.Dialog__position{
    right: auto!important;
    top: auto!important;
    left: 0!important;
    bottom: 0!important;
}
.ErrorModal__icon-container{
    flex: 0 0 auto;
    width: 100%;
    // @include tablet-min{
    //     width: 15%;
    // }

}
.ErrorModal__text-container{
    flex: 0 0 auto;
    width: 100%;
    // @include tablet-min{
    //     width: 70%;
    // }
}
.full-width{
  width: 900px !important;
}
.input-discount-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: $primary;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  background-color: #FFF;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.Toast {
    border-radius: 5px;
    padding: 15px;
    color: $dark-grey;
    background-color: white;
    border-style: solid;
    border-width: 1px;
}
.Toast__container {
    display: flex;
    align-items: center;
}

.Toast__text {
    margin-left: 1em;
}

.Toast__title {
    font-weight: bold;
}

.Toast__icon {
    font-size: 1.7em;
}

.Toast__title--success, .Toast__icon--success {
    color: $green;
}



.Toast__title--error, .Toast__icon--error {
    color: $red;
}
.Toast__title--pending, .Toast__icon--pending {
    color: $orange;
}

.Toast--success {
    border-color: $green;
}

.Toast--error {
    border-color: $red;
}
.Toast--pending {
    border-color: $orange;
}

@keyframes show_up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

.ProfilePage__data-remaining-days-container {
  position: fixed!important;
  z-index: 100010!important;
  transition: 1s;
  overflow: hidden;
  bottom: 5vh;
  animation: show_up 500ms ease-in ;
  width: 100%;
}

.ProfilePage__data-remaining-days {
  width: 95%;
  background-color: $bgbanner;
  border-radius: 15px;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: $white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #d0d0d0!important;
  margin: 0 auto!important;
  padding: 5px 20px 20px 20px !important;

  /*
  @media ( min-width: 468px){
    border-radius: 25px;
    bottom: 5vh;
    padding: 8px 20px;
  }
  */
}

.ProfilePage__title-remaining-days{
  color: $white;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6em;
  letter-spacing: -0.01em;
}
.ProfilePage__text-remaining-days{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: $white;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.Remaining-days__button {
  text-align: center;
  margin-bottom: 1em;
  margin-top: 1em;
  
  *.ActionButton--remaining-days {
    width: 95%;
    margin-left: 5px;
    margin-right: 5px;
    @media screen and (min-width:768px) and (max-width:992px){
      width: 45%;
    }
  }
  
}
.ActionButton--remaining-days {
  &:hover, &:visited, &:focus, &:focus-visible {
    color: $white;
    border: 1px solid $white;
  }
  &:focus-visible, &:focus  {
    outline: 0;
  }
  background-color: $white;
  color: $primary;
  border-radius: 25px;
  border: 1px solid $white;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 14px;
  padding: 5px;
  display: inline-block;
  font-weight: bold;
}

.ActionButton--remaining-days--primary {
  &:hover, &:visited {
    color: $bgbanner;
  }
  background-color: $white;
  color: $bgbanner;
}
.ActionButton--remaining-days--secondary {
  &:hover, &:visited {
    color: $white;
  }
  background-color: $bgbanner;
  color: $white;
  margin-top: 12px;
  border-radius: 99px;
  height: 33px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21.79px;
  text-align: center;
}
$number-dimension: 60px;
.ProfilePage__data-remaining-days-bgNumber{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 0px 5px;
  width: $number-dimension;
  height: $number-dimension;
  background: $white;
  color: $bgbanner;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  border-radius: 6px;
}
.ProfilePage__data-remaining-days-period {
  text-align: center;
  font-size: 0.8em;
  font-weight: normal;
}