
.auth__main {
    background-image: url("../../assets/images/FondoRegistro.jpg");
    background-repeat: repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    margin: 0px;
}

.auth__box-container--rounded-5 {
    border-radius: 0.5rem !important;   
}

.auth__box-container {
    background-color: $white;
    box-shadow: 0px 3px $dark-grey;
    border-radius: 2px;
    width: 459px;
    margin-top: 126px;
    margin-bottom: 126px;
    padding: 52px;
}
.auth__size-box-container{
    min-height: 550px;
    max-height: 550px;

}

.auth__title {
    color: $black;
    margin-top: 24px;
    margin-bottom: 17px;
    text-align: center;
    font-size: 28px;
    line-height: 39.2px;
    font-weight: 600;
}

.auth__form-label {
    font-weight: 600;
    line-height: 20.4px;
    font-size: 16px;
    letter-spacing: 2%;
}

.auth__required-input {
    color: $red;
}
.auth__comment {
  line-height: 20.4px;
  font-size: 14px;
  color: $dark-grey;
  font-style: italic;
}
.auth__title-register{
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  margin: 25px 0 15px 0;
  border-bottom: 3px solid $dark-grey;
}
.auth__form-check{

    box-shadow: none !important;
}
.auth__form-check:checked{
    background-color: $primary !important;
    border-color: $primary !important;
    box-shadow: none !important;
}
.auth__btn-form {
    width: 100%;
    border-radius: 99px;
    height: 42px;
    margin-top: 40px;
    background-color: $primary;
    font-weight: 600;
    font-size: 16px;
    line-height: 21.79px;
    color: $white;
    text-align: center;
    border: none;
    padding: 10px, 24px, 10px, 24px;
}

.auth__btn-form--disabled {
    background-color: $light-grey;
    color: $dark-grey;
}

.auth__account-exists {
    text-align: center;
    font-size: 16px;
    line-height: 25.6px;
    font-weight: 400;
    margin-top: 60px;
}

.auth__input::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 25.6px;
    font-style: italic;
    color: $dark-grey;
}
.auth__input:focus{
    @include input_focus
}
.auth__link  {
    &:hover {
        text-decoration: underline;
        color: $primary;
    }
    text-decoration: none;
    color: $primary;
}



.auth__input-phone {
    height: auto !important;
    width: 100% !important;
    //padding: 0.375rem 0.75rem !important;
    padding: 0.375rem 0.75rem 0.375rem 58px !important;
    font-size: 1rem !important;
}

.auth__input-phone:focus{
    outline: 1px solid $primary !important;
    border: 1px solid $primary !important;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
}
.auth__select:focus{
    outline: 1px solid $primary !important;
    border: 1px solid $primary !important;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
}
.auth__codes{
    text-align: center;
}

.auth__input-code {
    width: 36px;
    height: 52px;
    border-radius: 8px;
    margin: 7px;
    display: initial;
}
.auth__input-code:focus{
    outline: 1px solid $primary;
    border: 1px solid $primary;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.auth__input--error {
    border-color: $red;
}

.auth__input--ok {
    border-color: $primary;
}
.auth__inputError {
    color: $red;
}

.auth__errorMessage {
    color: $red;
    min-height: 1.5em;
    a{
        color: $red;
    }
    &:hover, &:visited {
        color: $red;
    }
}

.auth__errorMessage--2lines {
    min-height: 3.0em;
}


.auth__img-check {
    width: 60px !important;
    height: 60px !important;
}


div.auth__or-selector {
    position: relative;
    height: 2em;
    span {
        position: absolute;
        background-color: $white;
        color: $dark-grey;
        top: -0.65em;
        left: 45%;
        padding: 0 1em;
        font-weight: bold;
    }
}
@media (min-width: 577px) and (max-width: 991.98px) { 
    .auth__box-container {
        margin-top: 80px;
        margin-bottom: 80px;
    }
}
@media (min-width: 500px) and (max-width: 779px) {
  .auth__margin-steps{
    width: 10%;
  }
}
@media (min-width: 780px) {
  .auth__margin-steps-left{
    width: 20%;
  }
  .auth__margin-steps-right{
    width: 21%;
  }
}

@media (max-width: 576px) { 
    .auth__box-container {
        width: 90%;
        margin-top: 80px;
        margin-bottom: 80px;
        padding: 25px;
    }
}

.auth__numbers-container{
    display: flex;
    justify-content: center;
}
.auth__numbers-text-container{
    display: flex;
    justify-content: space-around;
}
.auth__number-passed{
    align-items: center;
    background-color: $primary;
    border-radius: 200px;
    color: $white;
    display: flex;
    font-size: 21px;
    height: 42px;
    justify-content: center;
    width: 42px;
    
}
.auth__number-blocked{
    align-items: center;
    background-color: $light-grey;
    border-radius: 200px;
    color: $black;
    display: flex;
    font-size: 21px;
    height: 42px;
    justify-content: center;
    width: 42px;
    
}
.auth__numbers-line-active{
    background-color: $primary;
    height: 2px!important;
    margin: auto -1px;
    opacity: 1;
    width: 45px;

}
.auth__numbers-line-blocked{
    background-color: $light-grey;
    height: 2px!important;
    margin: auto -1px;
    opacity: 1;
    width: 45px;
}
.auth__number-text-active{
    font-size: 12px;
    color: $black;
}

.auth__number-text-blocked{
    font-size: 12px;
    color: $dark-grey;
}


/*.auth__input {
    color: $dark-grey;
    border: 0px;
    border-bottom: 1px solid $light-grey;
    font-size: 16px;
    margin-bottom: 10px;
    height: 20px;
    width: 100%;

    transition: border-bottom .3s ease;
    
    &:focus {
        border-bottom: 1px solid $primary;
        outline: none;
    }
}

.auth__social-networks {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
}


.auth__alert-error {
    background-color: red;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding: 5px
}*/