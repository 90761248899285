$primary: #3BAC53;
$secondary: #349896;
$light-green: #95D848;
$seconday-light-green: #D3F7DD;
$black: #050505;
$dark-grey: #6E7274;
$light-grey: #EEF1F2;
$white: #F9FAF6; 
$red: #F9463B;
$green:$primary;
$blue:$secondary;
$yellow: #ffd900;
$bgbanner: #349896;
$orange: #d97e12;
$banner-eanistas: #edeef0;
$buttom-graduados: #35a8a5;
$buttom-eanistas: #8fd247;
$headband: #3BA952;
$primaryb2b: #90D348;
$secondaryb2b: #3BA952;
$greynew: #595959;
$greenb2b: #35A8A6;
$greenpay: #35ABA9;
$blackpay: #22252A;
$dark-greyb2b: #888888;
$greyb2b: #9D9D9E;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
$font-montserrat: 'Montserrat', sans-serif;

@font-face {
    font-family: 'BuiltTitlingSemiBoldItalic';
    src: url(../../fonts/builttitlingsbit.ttf) format('truetype');
}
@font-face {
    font-family: 'BuiltTitlingBoldItalic';
    src: url(../../fonts/builttitlingbdit.ttf) format('truetype');
}

@mixin small-phone {
    @media (max-width: 375px) {
        @content;
    }
}

.text_primary, a.text_primary:hover{
    color: $primary !important;
}
