.PaymentType__table-container{
    border: 1px solid $black;
    border-radius: 10px;
}
@media (max-width: 400px) {
  .PaymentType__td-left{
    padding: 10px 0 10px 15px;
    font-size: 15px;
    text-align: left;
    width: 60%;
  }
  .PaymentType__td-right{
    padding: 10px 0px 10px 0;
    font-weight: 600;
    font-size: 15px;
    min-width: 120px;
    width: 35%;
    text-align: right;
  }
}
@media (min-width: 400px) AND (max-width: 576px) {
  .PaymentType__td-left{
    padding: 10px 0 10px 15px;
    font-size: 16px;
    text-align: left;
    width: 60%;
  }
  .PaymentType__td-right{
    padding: 10px 0px 10px 0;
    font-weight: 600;
    font-size: 16px;
    min-width: 175px;
    width: 35%;
    text-align: right;
  }
}
@media (min-width: 576px) {
  .PaymentType__td-left{
    padding: 10px 0 10px 15px;
    font-size: 18px;
    text-align: left;
    width: 50%;
  }
  .PaymentType__td-right{
    padding: 10px 0px 10px 0;
    font-weight: 600;
    font-size: 18px;
    min-width: 120px;
    width: 35%;
    text-align: right;
  }
}

.PaymentType__table{
    border-collapse: separate;
    border-spacing: 0;
}
td.PaymentType__td-right {
  padding-right: 10px;
}