.Pagination__page-item,  .Pagination__page-link, .Pagination__page-link, .Pagination__page-link--nav {
  color: $black !important;
  font-weight: bold;
  &:focus {
    box-shadow: none;
    background-color: transparent;
  }
}
.Pagination__page-link {
  border: 1px solid $dark-grey;
  border-radius: 5px;
  &:hover {
    background-color: inherit;
    border: 1px solid $primary;
    color: $primary;
  }
}

.Pagination__page-link--nav {
  border: 0;
  &:hover {
    background-color: inherit;
    color: $primary;
  }
}

.Pagination__page-item--active {
  display: block !important;
  .Pagination__page-link {
    background-color: $primary !important;
    color: $white !important;
  };
}

.Pagination--loader {
  top: 40%;
  left: 20%;
  width:250px;
  img {
    width: 200px;
  };
  z-index: 100;
}



.pagination {
  margin: 0;

  &.is-centered {
    justify-content: center;
  }

  li {
    background-color: #fff;
    cursor: pointer;

    &.disabled {
      display: none;
    }
  }
}