// Mobile First - Min Resolutions

@mixin small-phone-min {
    @media (min-width: 375px) {
        @content;
    }
}

@mixin phone-min {
    @media (min-width: 480px) {
        @content;
    }
}

@mixin big-phone-min {
    @media (min-width: 640px) {
        @content;
    }
}

@mixin tablet-min {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin large-tablet-min {
    @media (min-width: 991px) {
        @content;
    }
}

@mixin desktop-min {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin big-desktop-min {
    @media (min-width: 1366px) {
        @content;
    }
}


@mixin input_focus {
    outline: 1px solid $primary;
    border: 1px solid $primary;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
}

@mixin input_focus_error {
    border-color: $red;
}

%transition-slow{
    transition: .7s ease-in-out;
}

%transition-normal{
    transition: .5s ease-in-out;
}

%transition-fast{
    transition: .3s ease-in-out;
}

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    
    @if $index {
      @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    
    @return $string;
}
