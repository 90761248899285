.CourseDetail__course-reviews{
    color: $secondary;
    font-size: 16px;
    font-weight: 700;
}

.CourseDetail__instructor-avatar-container{
    margin-top: 40px;
    margin-bottom: 24px;
    @include tablet-min{
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
.CourseDetail__instructor-info-wrapper{
    @include tablet-min{
        margin: 40px auto;
    }
}

.CourseDetail__instructor-info-container{
    background-color: $light-grey;
    color: $black;
}

.CourseDetail__instructor-name{
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 8px;
}

.CourseDetail__instructor-name-course-description{
    color: $primary !important;
    font-weight: 700 !important;
}

.CourseDetail__instructor-name-text{
    color: $dark-grey;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 8px;
}

.CourseDetail__see-more{
    color: $primary;
    cursor: pointer;
    font-weight: 700;
    text-decoration: underline;
    text-align: left;
}

.CourseDetail__separation-line{
    border: 1px solid $dark-grey;
    color: $dark-grey;
    margin: auto;
    opacity: 100% !important;
    // width: 83.33333333%;
}

.CourseDetail__suscription-button{
    &:focus-visible, &:focus  {
        outline: 0; 
    }
    align-content: center;
    background-color: $primary;
    border-radius: 99px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $white;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    padding: 10px 24px;
    width: 100%;
    position: relative;
    left: 50%;
    bottom: -0.8em;
    width: 90%;
    transform: translate(-50%,-50%)!important;
    @media (min-width: 992px) {
        position: static;
        //left: auto;
        //bottom: auto;
        width: 100%;
        transform: none !important;
    }
}


.CourseDetail__title{
    // font-family: 'BuiltTitlingBoldItalic';
    font-size: 41px;
    letter-spacing: -0.02em;
    font-weight: 700;
}

.CourseDetail__ul-bullets{
    list-style: none;
}

.CourseDetail__ul-bullets li::before{
    content: "\2022"; 
    color: $secondary; 
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; 
    width: 1em; 
    margin-left: -1em; 
}

.CourseDetail__wait-message {
    background-color: $white;
    border-radius: 15px !important;
    text-align: center;
    padding: 20px;
    img {
        width: 150px;
        margin-bottom: 5px;
    }
}