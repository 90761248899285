.SuccessfulCases__image{
    width: 100%;
    height: auto;
    border-radius: 100%;
}

.SuccessfulCases__text{
    color: $dark-grey;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
}

.SuccessfulCases__name{
    color: $dark-grey;
    font-weight: 700;
    font-size: 15px;
}

.SuccessfulCases__mb-100px{
    margin-bottom: 100px;
}