.LandingHeader__input{
    border: 1px solid $dark-grey;
    border-radius: 8px;
    box-sizing: border-box;
}
.LandingHeader__input:focus{
    outline: 1px solid $primary;
    border: 1px solid $primary;
}

.LandingHeader__navbar{
    overflow: visible;
    background-color: $white;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    padding-top: 23px;
    padding-bottom: 18px;
}

.LandingHeader__primary-button{
    background-color: $primary;
    border-radius: 50px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $white;
    padding: 8px 13px;
    
}
.LandingHeader__primary-button a{
    color: $white !important;
}
.LandingHeader__profile-menu{
    background-color: $white;
    border-radius: 8px;
    color: $dark-grey !important;
    filter: drop-shadow(0px 15px 12px rgba(0, 0, 0, 0.02)) drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
    padding: 15px;
    position: absolute;
    top: 50px;
    text-align: start;
    z-index: 10;
}
.LandingHeader__profile-menu-text-color{
    &:focus{
        color: $dark-grey !important;
    }
    &:hover{
        color: $dark-grey !important;
    }
    color: $dark-grey !important;

}

.LandingHeader__secondary-button{
    background-color: $white;
    border: 2px solid $primary;
    border-radius: 50px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $primary;
    padding: 5px 12px;
}

.LandingHeader__text-and-border{
    border-right: 2px solid $dark-grey;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    padding: 0px 10px;
    text-align: right;
}
.LandingHeader__text-black-color{
    &:focus{
        color: $black !important;
    }
    &:hover{
        color: $black !important;
        border-bottom: 3px solid $primary;
    }
    color: $black !important;
}
.LandingHeader__text-black-color-active{
    &:focus{
        color: $black !important;
    }
    &:hover{
        color: $black !important;
        border-bottom: 3px solid $primary;
    }
    color: $black !important;
    border-bottom: 3px solid $secondary;
}
.LandingHeader__text-no-border{
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    padding: 0px 10px;
}
.LandingHeader__user-icon-container{
    align-items: center;
    background-color: $primary;
    border-radius: 50px;
    display: flex;
    height: 40px;
    justify-content: center;
    min-width: 40px;
    width: 40px;
}
.LandingHeader__user-name-and-image{
    max-height: 42px;
}
.LandingHeader__user-name-dropdown-button{
    background-color: transparent;
    align-items: center;
    border: none;
    display: flex;
}
.LandingHeader__user-name-text{
    font-weight: 700;
    margin-right: 10px;
}

@media (max-width: 991px) {
    .LandingHeader__ml-auto-max-md{
        margin-left: auto;
    }
}
div[role='menu'] {
    visibility: hidden;
}

div[role='menu'].visible {
    visibility: visible;
}