.CourseCategoryCard__container-text{
    background-color: $light-grey;
    background-clip: content-box;
    height: 80px;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.CourseCategoryCard__clickable:hover  .CourseCategoryCard__container-text,
.CourseCategoryCard__clickable:hover ~ .CourseCategoryCard__container-text{
    background-color: $secondary;
    color: $white;
    transition: background-color .4s ease-in-out;
}
.CourseCategoryCard__image{
    width: 100%;
    height: auto;
    border-radius: 20px 20px 0px 0px;
}

.CourseCategoryCard__text{
    font-weight: 700;
    font-size: 16px;
}
.CourseCategoryCard__mb-100px{
    margin-bottom: 20px;
}

.CourseCategoryCard__clickable{
    cursor: pointer;
}

.CourseCategoryCard__clickable:hover {
    margin: -10px auto -15px auto;
    z-index: 1;
    zoom: 1.1;

}