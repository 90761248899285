.PlanAdmin__button-container{
    align-items: center;
    background-color: $white;
    border: solid 1px $dark-grey;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    height: 28px;
    justify-content: center;
    margin: 0px 5px;
    width: 28px;
}

.PlanAdmin__color-primary{
    color: $primary;
}

.PlanAdmin__color-secondary{
    color: $secondary;
}

.PlanAdmin__create-button{
    background-color: $primary;
    color: $white;
    align-items: center;
    padding: 5px 20px;
    gap: 10px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 99px;
    &:hover {
        color: $white;
    }
}

.PlanAdmin__find-plan-container{
    display: flex;
    justify-content: space-between;

    width: 794px;
    @include desktop-min{
        width: 930px;
    }
}

.PlanAdmin__input{
    border: 1px solid $dark-grey;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
    height: 35px;
    max-height: 35px;
    width: 150px;
    @include tablet-min{
        width: 380px;
    }
    @include desktop-min{
        width: 420px;
    }
}
.PlanAdmin__input:focus{
    outline: 1px solid $primary;
    border: 1px solid $primary;
}

.PlanAdmin__modal-base{
    background-color: $white;
    border-radius: 10px;
    font-size: 26px;
    font-weight: 600;
    left: 50%;
    line-height: 120%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 300px;
    @include big-phone-min{
        font-size: 28px;
        width: 350px;
    }
}

.PlanAdmin__modal-buttons-container{

    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

.PlanAdmin__modal-button-delete{
    background-color: $secondary;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 99px;
    color: $white;
    margin-bottom: 16px;
    width: 83.33%;
    

    display: inline-block;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;


}
.PlanAdmin__modal-button-cancel{
    background-color: $white;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 99px;
    color: $secondary;
    margin-bottom: 32px;
    width: 83.33%;


    display: inline-block;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid $secondary;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    // transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

}

.PlanAdmin__modal-container{
    display: flex;
    justify-content: center;
    margin-bottom: 38px;
    margin-top: 42px;
    text-align: center;
}

.PlanAdmin__table{
    
    border: 1px solid $dark-grey;
    border-radius: 8px;
    border-collapse: separate;
    border-spacing: 0px;
    min-width: 900px;
    overflow: hidden;
    width: 900px;
}

.PlanAdmin__table-header{
    background-color: $primary;
    // border: 1px solid $dark-grey;
    border-radius: 8px 8px 0px 0px;
    color: $white;

}

.PlanAdmin__table-row{
    height: 52px;
}

.PlanAdmin__table-pair-row{
    background-color: $light-grey;
}

.PlanAdmin__table-odd-row{
    background-color: $white;
}


.PlanAdmin__activate::before{
    content: "\2022"; 
    color: $primary; 
    font-weight: bold; /* If you want it to be bold */
    font-size: 20px;
    display: inline-block; 
    width: 1em; 
}

.PlanAdmin__inactivate::before{
    content: "\2022"; 
    color: $red; 
    font-weight: bold; /* If you want it to be bold */
    font-size: 20px;
    display: inline-block; 
    width: 1em; 
}

.PlanAdmin__outline-none:focus-visible{
    outline: none!important;
}
.aligncenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }