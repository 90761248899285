.ContactUs__box-container--rounded-5 {
    border-radius: 0.5rem !important;   
}

.ContactUs__box-container {
    background-color: $light-grey;
    border-radius: 2px;
    width: 459px;
    margin-top: 20px;
    margin-bottom: 50px;
    padding: 40px 52px 52px 52px;

    @media (max-width: 576px) { 
        width: 90%;
        padding: 25px;
    }

}

.ContactUs__btn-form {
    width: 100%;
    border-radius: 99px;
    height: 42px;
    margin-top: 40px;
    background-color: $primary;
    font-weight: 600;
    font-size: 16px;
    line-height: 21.79px;
    color: $white;
    text-align: center;
    border: none;
    padding: 10px, 24px, 10px, 24px;
}

.ContactUs__form-title-container{
    position: relative;
    z-index: 30;
}

.ContactUs__modal-base{
    background-color: $white;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 300px;
    @include big-phone-min{
        font-size: 27px;
        width: 400px;
    }
}
.ContactUs__modal-button-container{
    margin-bottom: 7px;
    margin-left: 23px;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    @include big-phone-min{
        margin-bottom: 9px;
        margin-left: 0px;
        margin-top: -2px;
        display: block;
        justify-content: left;
    }
}
.ContactUs__title{
    color: $white;
    font-weight: 700;
    font-size: 40px;
    line-height: 125%;
    margin-top: 48px;
}
.ContactUs__salutation{
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 18px;
    text-align: center;

}