.ProfilePage__banner-container{
    align-items: center;
    display: flex;
}
.ProfilePage__link-to-course{
    color: $primary;
    text-decoration: underline;
    &:hover {
        color: $secondary;
    }
}
.ProfilePage__banner-user-name{
    color: $white;
    font-family: 'BuiltTitlingBoldItalic';
    font-size: 45px;
    z-index: 50;
}

.ProfilePage__banner-text-container{
    margin-left: 10px;
    position: "absolute";
    @include small-phone-min {
        margin-left: 20px;
    }
    @include desktop-min{
        margin-left: 90px;
    }
    @include big-desktop-min{
        margin-left: 200px;
    }
}
.ProfilePage__banner-text{
    color: $white;
    font-size: 16px;
    margin-bottom: 0px;
    z-index: 50;
    
}
.ProfilePage__btn-form {
    width: 100%;
    border-radius: 99px;
    height: 42px;
    margin-top: 10px;
    background-color: $primary;
    font-weight: 600;
    font-size: 16px;
    line-height: 21.79px;
    color: $white;
    text-align: center;
    border: none;
    padding: 10px, 24px, 10px, 24px;
    @media (min-width: 1090px) {
        width: 230px;
    }
}
.ProfilePage__container-button{
    width: 100%;
    @media (min-width: 767px){
        width: 91.66666667%;
    }
}

.ProfilePage__container-course-title{
    text-align: center;
    @include tablet-min{
        text-align: left;
    }
}

.ProfilePage__modal-title{
    //color: $primary;
    //font-size: 28px;
    //font-weight: 700;
    //margin-top: 20px;
    text-align: center;
    font-size: 2.0rem;
    font-weight: bold;
    color: $black;
    margin-bottom: 0.3em;
}

.ProfilePage__color-green{
    color: $primary;
}
.ProfilePage__course-in-progress-row{
    margin-top: 24px;
    // flex-wrap: wrap;
    @media(min-width: 768px){
        // flex-wrap: nowrap;
    }
    @media ( min-width: 830px){
        margin-left: 24px;
        font-size: 16px;
    }
}
.ProfilePage__course-in-studyplan-row{
    margin-top: 16px;
    @media(min-width: 768px){
    }
    @media ( min-width: 830px){
        margin-left: 16px;
        font-size: 16px;
    }
}
.ProfilePage__courses-manager-container{
    background-color: $white;
    border-radius: 16px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin: 20px;
    width: 290px;
    @include phone-min{
        width: 350px;
    }
    @media ( min-width: 830px){
        width: 489px;
    }
    @media (min-width: 992px) {
        
        width: 650px;
    }
    @media ( min-width: 1090px){
    
        width: 730px;
    }
}

.ProfilePage__courses-manager-menu{
    margin-top: 16px;
    margin-left: auto;
    font-size: 12px;
    font-weight: 700;
    height: 42px;
    @media ( min-width: 830px){
        margin-left: 24px;
        font-size: 16px;
    }
}

.ProfilePage__courses-manager-menu div .menu-view{
    color: $black;
    cursor: pointer;
}

.ProfilePage__courses-manager-menu div .menu-view-active{
    border-bottom: solid 3px $primary;
    color: $black;
    cursor: pointer;
    padding-bottom: 1px;

}
.ProfilePage__course-title{
    color: $black;
    font-weight: 700;
    margin-bottom: 5px;
    cursor: pointer;
}
.ProfilePage__course-instructor-name{
    color: $dark-grey;
    font-size: 12px;
    font-weight: 600;

}

.ProfilePage__copy-link-button{
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    
    /* Primario/ Blanco */
    
    background: $white;
    
    border: 1px solid $dark-grey;
    border-radius: 8px;
    color: $dark-grey;    
    font-size: 12px;
    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer;
    &:hover, &:visited {
        color: $dark-grey;    
    }
}

.ProfilePage__copy-link-button--disabled {
    cursor: not-allowed;
}


.ProfilePage__download-certificate-button {
    cursor: pointer;
    align-items: center;
    color: $white;
    background: $primary;
    border-radius: 8px;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-grow: 0;
    font-size: 12px;
    font-weight: 700;
    justify-content: center;
    padding: 8px 16px;
    order: 0;
}

.ProfilePage__download-certificate-button--disabled {
    color: $dark-grey;
    background: $light-grey;
    cursor: not-allowed;
}

.ProfilePage__download-certificate-button--disabled-green {
    color: $dark-grey;
    background: $seconday-light-green;
    cursor: not-allowed;
}

.ProfilePage__form-label {
    font-weight: 600;
    line-height: 20.4px;
    font-size: 16px;
    letter-spacing: 2%;
}

.ProfilePage__finished-course-image{
    width: 226px; 
    height:128px; 
    border-radius: 8px;
    cursor: pointer;
    @media (min-width:768px ){
        width: 130px;
        height: 73px;
    }
    @media (min-width:992px ){
        width: 226px;
        height: 128px;
    }
}

.ProfilePage__in-progress-course-image{
    
        width: 226px;
        height: 128px;
        border-radius: 8px;
        cursor: pointer;
        @media (min-width:768px ){
            width: 130px;
            height: 73px;
        }
        @include large-tablet-min{
            width: 226px;
            height: 128px;
        }
}
.ProfilePage__info-email-no-change{
    font-size: 12px;
    margin-left: 2px;
    max-width: 320px;
    text-align: justify;
}
.ProfilePage__info-error{
    font-size: 21px;
    margin-left: 2px;
    max-width: 320px;
    text-align: justify;
}
.ProfilePage__input{
    width: 230px;
    @include phone-min{
        width: 290px;
    }
    @media (min-width: 1090px) {
        
        width: 320px;
    }
}

.ProfilePage__input::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 25.6px;
    font-style: italic;
    color: $dark-grey;
}

.ProfilePage__input:focus{
    outline: 1px solid $primary;
    border: 1px solid $primary;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.ProfilePage__input-flex{
    display: flex;
    flex-wrap: wrap;
    @media (min-width: 1090px){
        justify-content: space-between
    }
}

.ProfilePage__input-phone {
    height: auto !important;
    width: 230px !important;
    padding: 0.375rem 0.75rem 0.375rem 58px !important;
    font-size: 1rem !important;
    @include phone-min{
        width: 290px!important;
    }
    @media (min-width: 1090px){
        width: 320px!important;
    }
}

.ProfilePage__input-phone:focus{
    outline: 1px solid $primary !important;
    border: 1px solid $primary !important;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
}
.ProfilePage__input--error {
    border-color: $red;
}

.ProfilePage__input--ok {
    border-color: $primary;
}
.ProfilePage__inputError {
    color: $red;
}

.ProfilePage__menu-container{
    background-color: $white;
    border-radius: 16px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 290px;
    max-height: 250px;

    margin: 20px;

    @include phone-min{
        width: 350px;
    }
    @include tablet-min{
        width: 285px;
    }
    @include desktop-min{
        width: 290px;
    }
}


.ProfilePage__menu-item{
    background-color: $white;
    border-radius: 8px;
    cursor: pointer;
    margin: 8px auto;
    padding: 8px;
}

.ProfilePage__menu-item .ProfilePage__menu-icon{
    margin-left: 5px;
    color: $dark-grey;
}

.ProfilePage__menu-item .ProfilePage__menu-text{
    color: $dark-grey;
    font-weight: 600;
    margin: auto 5px;
}

.ProfilePage__menu-item-active{
    background-color: $seconday-light-green;
    border-radius: 8px;
    cursor: pointer;
    margin: 8px auto;
    padding: 8px;
}

.ProfilePage__menu-item-active .ProfilePage__menu-icon{
    margin-left: 5px;
    color: $primary;
}

.ProfilePage__menu-item-active .ProfilePage__menu-text{
    color: $black;
    font-weight: 600;
    margin: auto 5px;
}


.ProfilePage__menu-margins{
    margin: 16px auto;
}

.ProfilePage__modal-base{
    background-color: $white;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 300px;
    @include big-phone-min{
        width: 399px;
        font-size: 30px;
    }
}
.ProfilePage__modal-button-container{
    margin-top: 0px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    @include big-phone-min{
        margin-top: -2px;
        margin-bottom: 9px;
        display: block;
        justify-content: left;
    }
}
.ProfilePage__modal-button-accept{
    &:focus-visible{
        outline: none!important;
    }
    background-color: $primary;
    border: none;
    border-radius: 25px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
    width: 266px;
}
.ProfilePage__modal-button-cancel{
    &:focus-visible{
        outline: none!important;
    }
    background-color: $white;
    border-radius: 25px;
    border: 2px solid $primary;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $primary;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
    width: 266px;
}
.ProfilePage__modal-button-yes{
    align-content: center;
    background-color: $primary;
    border-radius: 8px;
    border: none;
    color: $white;
    display: inline-block;
    flex-wrap: wrap;
    font-size: 1rem;
    font-weight: 700;
    height: 25px;
    line-height: 1.5;
    margin-right: 10px;
    padding: 0.375rem 0.75rem;
    text-align: center;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;
    width: 60px;
}
.ProfilePage__modal-button-OK{
    align-content: center;
    background-color: $primary;
    border-radius: 8px;
    border: none;
    color: $white;
    display: inline-block;
    flex-wrap: wrap;
    font-size: 1rem;
    font-weight: 700;
    height: 25px;
    line-height: 1.5;
    margin-bottom: 8px;
    padding: 0.8rem 0.75rem;
    text-align: center;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;

    margin-left: 23px;
    
    @include big-phone-min{
        margin-left: 0px;
    }
}

.ProfilePage__modal-button-no {
    align-content: center;
    background-color: $secondary;
    border-radius: 8px;
    border: none;
    color: $white;
    display: inline-block;
    flex-wrap: wrap;
    font-size: 1rem;
    font-weight: 700;
    height: 25px;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    text-align: center;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;
    width: 60px;
}

.ProfilePage__modal-button--highlight {
    background-color: $primary !important;
    color: $white !important;
    &:hover {
        color: $white !important;
    }
}

.ProfilePage__modal-button {
    border-radius: 20px;
    font-weight: 700;
    margin-bottom: 8px;
    padding: 0.4rem 2.5rem;
    text-align: center;
    color: $primary;
    background-color: $white;
    border: 1px solid $primary;
    &:hover {
        color: $primary;
    }
}



.ProfilePage__modal-icon {
    margin-bottom: 15px;
}


.ProfilePage__modal-icon--blue {
    color: $blue;
}

.ProfilePage__modal-icon--green {
    color: $green;
}

.ProfilePage__modal-info-container{
    flex: 0 0 auto;
    padding: 0px;
    width: 270px;

    @include big-phone-min{
        width: 319px;
    }
}

.ProfilePage__modal-text{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
    line-height: 1.27rem;
    margin-right: 10px;
    margin-left:15px ;
    text-align: center;
    @include big-phone-min{
        margin-right: 25px;
        margin-left: 0px;
    }
}

.ProfilePage__modal-text-description{
    color: $black;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.ProfilePage__modal-left-margin{
    @include big-phone-min{
        margin-left:10px;
    }
}


.ProfilePage__modal-buttons-container {
    display: flex;
    flex-direction: column;
    margin-top: 1.2em;
    @media ( min-width: 768px){
        padding: 0 3.5em;
    }


}

.ProfilePage__outline-none:focus-visible{
    outline: none!important;
}
.ProfilePage__watchout-modal-text{
    font-size: 16px;
    font-weight: 400;
    // text-align: justify;
    width: 240px;
    margin-bottom: 0px;
    @include big-phone-min{
        margin-right: 30px;
        margin-left:0px ;
    }
}

.ProfilePage__select:focus{
    outline: 1px solid $primary !important;
    border: 1px solid $primary !important;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.ProfilePage__share-buttons-container{
    text-align: center;
    @include tablet-min{
        text-align: left;
    }
}


.ProfilePage__share-buttons {
    margin-right: 8px;
}

.ProfilePage__share-buttons--linkedin {
    color: #007BB5;
}

.ProfilePage__share-buttons--twitter-square {
    color: #60A9DE;  
}

.ProfilePage__share-buttons--whatsapp {
    color: #25D366;  
}

.ProfilePage__share-buttons--disabled {
    //color: #dfe1e2;
    opacity: 0.33;
    cursor: not-allowed;
}


.ProfilePage__user-info-container{
    background-color: $light-grey;
    border-radius: 16px;
    margin: 20px;
    width: 290px;
    @include phone-min{
        width: 350px;
    }
    @media ( min-width: 1090px){
    
        width: 730px;
    }
}

.ProfilePage__no-certification-warning-text {
    border-radius: 5px;
    color: $secondary;
    border: 1px solid $secondary;
    padding-left: 0.5em;
}
.ProfilePage__content-data-payments {
  display: inline-flex;
}
.ProfilePage__data-payments {
  border-radius: 5px;
  color: $black;
  border: 1px solid $black;
  padding: 1em 0em 1em 1.5em;
}
.ProfilePage__data-payments-active {
  color: $primary;
}
.ProfilePage__data-payments-pending {
  color: $orange;
}
.ProfilePage__data-payments-inactive {
  color: $dark-grey;
}
.ProfilePage__data-payments-status {
  font-weight: bold;
  font-size: 0.85em;
}
.ProfilePage__data-payments-price {
  font-size: 0.75em;
  align-self: center;
}
.ProfilePage__data-payments-status li::marker {
  content: "\2022";
  display: inline-block;
  font-size: 1.3em;
  font-weight: bold;
  width: 0.8em;
  margin-left: -1em;
}
.ProfilePage__data-payments-flex{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: center;
}
@media (max-width: 829px) {
  .ProfilePage__data-payments-left {
    width: 100%;
  }
  .ProfilePage__data-payments-right {
    width: 100%;
  }
}
@media (min-width: 830px) {
  .ProfilePage__data-payments-left {
    width: 50%;
  }
  .ProfilePage__data-payments-right {
    width: 50%;
  }
}
.ProfilePage__alert-no-certifications {
  display: flex;
  width: 380px;
}
.ProfilePage__data-alert-no-certifications {
  border-radius: 5px;
  color: $black;
  background-color: $yellow;
  font-size: 0.75em;
  font-weight: bold;
  padding: 0.4em 0em 0.4em 1em;
  margin-left: 30px;
}
.ProfilePage__conditions-password, .ProfilePage__info-error {
    font-size: 14px !important;
    margin: 2px 0 0 0 !important;
    text-align: justify;
}
.ProfilePage__change-password {
    background-color: $white;
    border-radius: 25px;
    border: 2px solid $primary;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $primary;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    padding: 6px;
    margin-top: 20px;
    height: 42px;
    width: 230px;
    text-align: center;
}
.ProfilePage__label_survey, .ProfilePage__input_survey{
    font-size: 10px;
    vertical-align: middle;
    display: inline-flex;
    width: 40px;
  }