.CourseCard__container-text{
    background-color: $light-grey;
    background-clip: content-box;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.CourseCard__last-card-margin-top{
    margin-top: -185px;
}

.CourseCard__text{
    font-weight: 700;
    font-size: 0.85rem;
    color: $black;
    min-height: 41px;
    display: flex;
    align-items: center;
}

.CourseCard__text-instructor{
    font-weight: 600;
    font-size: 0.8rem;
    color: $dark-grey;
}

.CourseCard__image{
    width: 100%;
    height: auto;
    border-radius: 20px 20px 0px 0px;
}
.CourseCard__time {
    background-color: $light-grey;
    color: $dark-grey;
    font-size: 14px;
    font-weight: bold;
    border-radius: 15px;
    height: 34px;
    position: absolute;
    width: 80px;
    padding-top: 6px;
    bottom: 7%;
    right: 10%;
}
.CourseCard__price {
    background-color: $light-grey;
    color: $dark-grey;
    font-size: 14px;
    font-weight: bold;
    border-radius: 15px;
    height: 34px;
    position: absolute;
    width: 100px;
    padding-top: 6px;
    bottom: 7%;
    left: 10%;
}

.CatalogPage__search-category{
  margin-left: -10px;
}
#c0 input:checked ~ .checkmark {
  background-color: green; border: none;
}
#c9 input:checked ~ .checkmark {
  background-color: green; border: none;
}
#c10 input:checked ~ .checkmark {
  background-color: green; border: none;
}
#c11 input:checked ~ .checkmark {
  background-color: green; border: none;
}
#c12 input:checked ~ .checkmark {
  background-color: green; border: none;
}
#c13 input:checked ~ .checkmark {
  background-color: green; border: none;
}
#c14 input:checked ~ .checkmark {
  background-color: green; border: none;
}
#c15 input:checked ~ .checkmark {
  background-color: green; border: none;
}

.container {
  display: block;
  position: relative;
  cursor: pointer;
}

.container input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: $white;
  border: 2px solid $dark-grey;
  margin-left: 5px;
  margin-top: -6px;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}