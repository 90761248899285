$icon-size: 18px;
$icon-margin: 0.5rem;

.CatalogPage__filter-container{
    background-color: $light-grey;
    border-radius: 8px;
    color: $black;
    padding: 16px;
}

.CoursePage__categories-title{
    color: $dark-grey;
    font-size: 21px;
    font-weight: 600;
}

.CatalogPage__filter-text{
    font-size: 16px;
    font-weight: 600;
    margin-left: 15px;
}
.CatalogPage__order-text{
    color: $black;
    font-size: 16px;
    font-weight: 600;
    margin: 10px auto;
    cursor: pointer;
}
.CatalogPage__orders-container{
    border-bottom: solid 2px $light-grey;
}
.CatalogPage__title{
    color: $black;
    font-size: 32px;
    font-weight: 700;
}
.CatalogPage__results{
    color: $dark-grey;
    font-size: 16px;
    font-weight: 700;
}

.CatalogPage__Filter-Modal{
  position: absolute;
  right: 0%;
  width: 250px;
  @include phone-min{
    width: 300px;
  }
  height: 100%;
  background-color: $white;
  overflow-y: scroll;
  overflow-x: hidden;
}

.CatalogPage__form-radio-input{
    --text: #6E7274;
    --radio: #6E7274;
    --radio-checked: #3BAC53;
    --radio-size: 20px;
    --width: 100px;    
    --height: 140px;
    --border-radius: 10px;

    border-radius: var(--border-radius);
    color: var(--text);
    font-size: 16px;
    position: relative;
    padding: 20px 15px;
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: auto var(--radio-size);
    align-items: center;
    input[type="radio"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      position: relative;
      height: 20px;
      width: 20px;
      min-width: 20px;
      min-height: 20px;
      outline: none;
      margin: 0;
      cursor: pointer;
      border: 2px solid var(--radio);
      background: transparent;
      border-radius: 50%;
      display: grid;
      justify-self: end;
      justify-items: center;
      align-items: center;
      overflow: hidden;
      transition: border .5s ease;
      &::before,
      &::after {
        content: "";
        display: flex;
        justify-self: center;
        border-radius: 50%;
      }
      &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: 1;
        opacity: var(--opacity, 1);
      }
      &::after {
        position: relative;
        width: calc(130% /2);
        height: calc(130% /2);
        background: var(--radio-checked);
        top: var(--y, 100%);
        
      }
      &:checked {
        --radio: var(--radio-checked);
        &::after {
          --y: 0%;
        }
        &::before {
          --opacity: 0;
        }
        ~ input[type="radio"] {
          &::after {
            --y: -100%;
          }
        }
      }
      &:not(:checked) {
        &::before {
          --opacity: 1;
          transition: opacity 0s linear .5s;
        }
      }
    }
  }

.CatalogPage__form-radio-input label{
    margin-left: 10px;
    cursor: pointer;
}
.CatalogPage__position-absolute{

  @include big-phone-min{
    position: absolute;
    width: 390px;
  }
  @include tablet-min{
    width: 460px;
  }
  @media (min-width: 893px) {
    width: 300px;
  }
  @include desktop-min{
    width: 360px;
  }
  @include big-desktop-min{
    width: 430px;
  }
  
}

.CatalogPage__radio-input-container{
    margin: 13px auto;
}

.CatalogPage__page-item,  .CatalogPage__page-link, .CatalogPage__page-link, .CatalogPage__page-link--nav {
    color: $black !important;
    font-weight: bold;
    &:focus {
      box-shadow: none;
      background-color: transparent;
    }
  }
  .CatalogPage__page-link {
    border: 1px solid $dark-grey;
    border-radius: 5px;
    &:hover {
      background-color: inherit;
      border: 1px solid $primary;
      color: $primary;
    }
  }

  .CatalogPage__page-link--nav {
    border: 0;
    &:hover {
      background-color: inherit;
      color: $primary;
    }
  }

.CatalogPage__page-item--active {
  display: block !important;
  .CatalogPage__page-link {
    background-color: $primary !important;
    color: $white !important;
  };
}

.CatalogPage--loader {
  top: 40%;
  left: 20%; 
  width:250px;
  img {
    width: 200px;
  };
  z-index: 100;
}
@media screen and (min-width: 576px) {
  .CatalogPage--loader {
    left: 35%; 
    top: 35%;
  }
}

.CatalogPage__filter-menu {
  font-size: 16px;
  position: relative;
  padding: 20px 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.CatalogPage__category {
  color: $dark-grey;
  margin: 13px auto;
  cursor:pointer;
}

.CatalogPage__category--selected {
  color: $black;
}

.CatalogPage__icon {
  mask-size: $icon-size $icon-size;
  mask-repeat: no-repeat;
  mask-position: left;
  min-width: $icon-size;
  height: $icon-size;
  margin-right: $icon-margin;
  background-color: $dark-grey;
}

.CatalogPage__icon--selected {
  background-color: $black;
}
.CatalogPage__bold {
  font-weight: bold;
  border-bottom: 3px solid #349896;
}

.CatalogPage__right-order {
  width: 240px;
  display: flex;
}

.CatalogPage__titleorder{
  margin-top: 2px;
  font-size: 0.9rem;
}

.CatalogPage__selectOrder {
  border: none;
  font-weight: bold;
  color: $green;
  margin-left: 1em;
}

.CatalogPage__select {
  font-size: 0.9rem;
  margin-left: 20px;
  color: $primary;
  font-weight: bold;
  border: none;
  min-width: 130px;
}

.CatalogPage__icon-cat-todos {
  mask-image: url(../../../assets/images/icons/course_category/00-todos.svg);
}
.CatalogPage__icon-cat-mark-ventas {
  mask-image: url(../../../assets/images/icons/course_category/01-mark-ventas.svg) ;
}
.CatalogPage__icon-cat-emp-ger-adm {
  mask-image: url(../../../assets/images/icons/course_category/02-emp-ger-adm.svg) ;
}
.CatalogPage__icon-cat-proy-inno {
  mask-image: url(../../../assets/images/icons/course_category/03-proy-inno.svg) ;
}
.CatalogPage__icon-cat-sost-incl {
  mask-image: url(../../../assets/images/icons/course_category/04-sost-incl.svg) ;
}
.CatalogPage__icon-cat-fin-eco-inv {
  mask-image: url(../../../assets/images/icons/course_category/05-fin-eco-inv.svg) ;
}
.CatalogPage__icon-cat-hab-hum {
  mask-image: url(../../../assets/images/icons/course_category/06-hab-hum.svg) ;
}
.CatalogPage__icon-cat-tecno {
  mask-image: url(../../../assets/images/icons/course_category/07-tecno.svg) ;
}
.CatalogPage__icon-cat-idiomas {
  mask-image: url(../../../assets/images/icons/course_category/08-idiomas.svg) ;
}
.CatalogPage__icon-cat-microcredenciales {
  mask-image: url(../../../assets/images/icons/course_category/09-microcredenciales.svg) ;
}

.video-iframe {
  width: 100%;
  border: none;
}