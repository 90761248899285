.FilterAdmin__input{
  border: 1px solid $dark-grey;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 16px;
  height: 35px;
  width: 95%;
  @media (max-width: 804px){
    width: 100%;
  }
  margin: 10px 10px 0 0;
  padding-left: 10px;
}
.FilterAdmin__options{
  text-align: justify;
  width: 24%;
  @media (max-width: 804px){
    width: 100%;
  }
}
.FilterAdmin__input-title{
  text-align: left;
  font-size: 14px;
  font-weight: 600;
}
.FilterAdmin__select{
  border: 1px solid $dark-grey;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 16px;
  height: 40px;
  max-height: 40px;
  width: 25%;
  margin: 10px 0 10px 10px;
  @include tablet-min{
    width: 380px;
  }
  @include desktop-min{
    width: 24%;
  }
}
.FilterAdmin__input:focus{
  outline: 1px solid $primary;
  border: 1px solid $primary;
}

.FilterAdmin__input-group{
  align-items: center;
  display: flex;
  width: 93%;
  margin-left: 5%;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.FilterAdmin__buttons-group{
  text-align: center;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  width: 95%;
  margin-top: 12px;
  display: flex;
  gap: 0.5em;
}

.FilterAdmin__search-button{
  background-color: $primary;
  color: $white;
  align-items: center;
  margin-left: 10px;
  padding: 5px 20px;
  gap: 10px;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 99px;
  border: none;
  &:hover {
    color: $white;
  }
}
.FilterAdmin__option-button{
  background-color: $primary;
  color: $white;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 5px 20px;
  gap: 10px;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 99px;
  border: none;
  &:hover {
    color: $white;
  }
}

.FilterAdmin__downloadcsv-button{
  background-color: $primary;
  color: $white;
  align-items: center;
  padding: 5px 20px 5px 20px;
  gap: 10px;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 99px;
  &:hover {
    color: $white;
  }
}
.overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: auto;
  max-width: 160px;
}
.FilterAdmin__options-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
}
.FilterAdmin__options-list li {
  display: flex;
  align-items: center;
  font-size: 16px;
}
@media (max-width: 768px) {
  .FilterAdmin__options-list {
      grid-template-columns: 1fr;
  }
}
.overflow_options {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: auto;
  max-width: 250px;
}