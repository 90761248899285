

.LandingB2BHeader__input{
    border: 1px solid $dark-grey;
    border-radius: 8px;
    box-sizing: border-box;
}
.LandingB2BHeader__input:focus{
    outline: 1px solid $primary;
    border: 1px solid $primary;
}

.LandingB2BHeader__navbar{
    overflow: visible;
    background-color: $black;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    padding-top: 8px;
    padding-bottom: 3px;
}

.LandingB2BHeader__primary-button{    
    font-family: $font-montserrat;
    border-color: $white;
    border: 1px solid;
    border-radius: 17px;
    color: $white;
    padding: 8px;
    @media (min-width: 992px) {
        width: auto;
    }
}
.LandingB2BHeader__primary-button a{
    color: $white !important;
}

.LandingB2BHeader__user-icon-container{
    align-items: center;
    background-color: $primary;
    border-radius: 50px;
    display: flex;
    height: 40px;
    justify-content: center;
    min-width: 40px;
    width: 40px;
}
.LandingB2BHeader__user-name-and-image{
    max-height: 42px;
}
.LandingB2BHeader__user-name-dropdown-button{
    background-color: transparent;
    align-items: center;
    border: none;
    display: flex;
}
.LandingB2BHeader__user-name-text{
    font-weight: 700;
    margin-right: 10px;
}

div[role='menu'] {
    visibility: hidden;
}

div[role='menu'].visible {
    visibility: visible;
}

