//$image: $primary;


$form-switch-checked-bg-image: "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#FFFFFF'/></svg>" !default;
$form-switch-focus-bg-image: "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$primary}'/></svg>" !default;

$escaped-characters: (
                ("<", "%3c"),
                (">", "%3e"),
                ("#", "%23")
) !default;

@function escape-bg-image($image) {
  $string: '';
  @each $char,$escape in $escaped-characters {
    $string: str-replace($image,$char,$escape);
  }
  @return $string;
}



.UserFormContainer {
  border-radius: 10px;
  border: 1px solid $dark-grey;
}

.UserFormContainer__label {
  font-weight: bold;
}
.UserFormContainer__sublabel {
  width: 100%;
  margin-left: 20px;
}

.UserFormContainer__input:focus {
  @include input_focus;
}

.UserFormContainer__input--error {
  @include input_focus_error;
}


.form-switch {
  .UserFormContainer__check-input {
    font-size: 1.2em;
    margin-left: -2em;
    margin-top: .1em;
    margin-right: .4em;
  }
  .UserFormContainer__check-input:focus {
    @include input_focus;
    background-image:  url(escape-bg-image($form-switch-focus-bg-image));
  }

  .UserFormContainer__check-input:checked {
    background-color: $primary;
    border-color: $primary;
    background-image:  url(escape-bg-image($form-switch-checked-bg-image));
  }
}
.UserFormContainer__group {
  border-radius: 10px;
  border: 1px solid $dark-grey;
  padding: 10px 20px;
}

.UserFormContainer__benefit-button {
  border: 1px solid $dark-grey;
  border-radius: 3px;
  padding: 3px 6px;
}

.UserFormContainer__benefit-button--delete {
  color: $red;
}
.UserFormContainer__benefit-button--delete-lastone{
  color: $dark-grey;
}


.UserFormContainer__plan-benefit {
  border-bottom: 1px solid #a1b8b8;
  margin-bottom: 20px;
  padding-bottom: 8px;
}

.UserPage__saved-plan-toast {
  color: $white;
  padding: 5px;
  font-size: 1.2em;
  font-weight: bold;
  border-radius: 5px;
  margin-top: -15%;
}

.UserPage__saved-plan-toast--ok {
  background-color: $primary;
}

.UserPage__saved-plan-toast--error {
  background-color: $red;
}

.UserPayHistoryList{

}
.UserFormContainer__edit-button{
  color: $dark-grey;
  background-color: $white;
  align-items: center;
  border: none;
  &:hover {
    color: $black;
  }
}