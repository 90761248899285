html, body {
    max-width: 100%;
    overflow-x: hidden;
}
body{
    // font-family: 'Open Sans', sans-serif;
    background-color: $white;
}
a{
    text-decoration: none;
}
