.BestCourses__button-primary{      
    background-color: $primary;
    border-radius: 25px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $white;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 0px;
    cursor: pointer;
    text-align: center;
    width: 100%;
}

.BestCourses__container-text{
    background-color: $secondary;
    background-clip: content-box;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    height: 80px;

}
.BestCourses__container-text-active{
    background-color: $white;
    background-clip: content-box;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    // height: 150px;
}

.BestCourses__container-text-active .BestCourses__text{
    color: $black;
}

.BestCourses__course-info-text{
    color: $dark-grey;
    font-size: 0.68rem;
    font-weight: 600;
    flex-grow: 4;
}

.BestCourses__h-350px{
    height: 350px;
}

.BestCourses__image{
    width: 100%;
    height: auto;
    border-radius: 20px 20px 0px 0px;
}

.BestCourses__indicator-margin{
    margin-bottom: 100px;
    @media (max-width: 576px){
        margin-bottom: 40px;
    }
}

.BestCourses__new-course-advertise{
    background-color: $primary;
    border-radius: 25px;
    color: $white;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    margin-top: 1em;
    margin-left: 1em;
    position: absolute;
    padding: 8px 10px;
}

.BestCourses__text{
    font-weight: 700;
    font-size: 0.85rem;
    color: $white;
}

.BestCourses__text-instructor{
    font-weight: 600;
    font-size: 0.7rem;
    color: $white;
}

.BestCourses__text-description{
    color: $dark-grey;
    font-size: 0.8rem;
    font-weight: 600;
}

.BestCourses__text-color-green{
    color: $primary;
}

.BestCourses__detail-icon {
    width: 18px;
    height: auto;
    padding-right: 5px;
}