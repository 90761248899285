
#zoom_messenger_plugin_iframe.zoom_messenger_plugin_iframe{
    /* position: fixed!important; */
    bottom: 0!important;
    right: 10px!important;
    background-color: white;
    padding: 10px;
    z-index:100005!important;
    transition: 1s;
    /* border: 1px solid grey!important; */
    width:100%;    
    /* height:500px; */
    height:calc(100% - 35px);
    margin: 0px;
    overflow: hidden;
    margin:0!important;
    padding:0!important;
}
#zoom_messenger_plugin_iframe_minimized.zoom_messenger_plugin_iframe_minimized{
    position: fixed!important;
    bottom: 0!important;
    right: 10px!important;
    background-color:#E6EAEA;
    padding: 10px;
    z-index:100005!important;
    transition: 1s;
    border: 1px solid #d0d0d0!important;
    width:300px;    
    height:50px;
    margin: 0px;
    overflow: hidden;
    margin:0!important;
    padding:5px 0!important;
    border-radius: 5px 10px 0px 50px;
    cursor:pointer;
}
#zoom_messenger_plugin_iframe_minimized_encabezado{
    /* padding-left:60px;
    padding-right: 48px; */
    height:40px;
    padding-left:39px;
    padding-right: 23px;
}
#zoom_messenger_plugin_iframe_minimized_encabezado p{
    font-family: fantasy;
    margin:0;
    margin-top:3px;
    font-size: 22px;
}
#zoom_messenger_plugin_iframe_minimized_encabezado img{
    /* width:40px; */
    display:initial;
    margin-right:10px;
    max-height: 40px;
    max-width: 65px;
}
#zoom_messenger_plugin_iframe_btnClose{
    display:none;
    position: absolute;
    right: 10px;
    font-size: 14px;
    top: 12px;
}
#zoom_messenger_plugin_iframe_btnExpand{
    display:none;
    left: 10px;
    position: absolute;
    font-size: 18px;
    top: 10px;
}
#zoom_messenger_plugin_iframe_btnHome{
    display:none;
    left: 10px;
    position: absolute;
}
.zoom_messenger_plugin_iframe_leftIcon{
    right: inherit!important;
    font-size: 18px!important;
    top: 10px!important;
    left: 10px!important;
}
.zoom_messenger_plugin_iframe_minimized_ctaMessage{
    position: fixed;
    background-color: white;
    box-shadow: 0px 0px 4px 0px grey;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 13px;
    opacity:0;
    display:none;
    cursor:default;
    max-width: 270px;
    z-index: 100005!important;
}
.zoom_messenger_plugin_iframe_minimized_ctaMessage p {
    margin:0;
}

/* ESTILOS PARA LA BURBUJA FLOTANTE */
#zoom_messenger_plugin_iframe_minimized_flotante.zoom_messenger_plugin_iframe_minimized{
    position: fixed!important;
    right: 10px!important;
    background-color:#E6EAEA;
    padding: 10px;
    z-index:100005!important;
    transition: 1s;
    border: 1px solid #d0d0d0!important;
    width:110px;    
    height:110px;
    margin: 0px;
    overflow: hidden;
    margin:0!important;
    padding:5px 0!important;
    border-radius: 50px 50px 0px 50px;
    cursor:pointer;
}
#zoom_messenger_plugin_iframe_minimized_flotante.zoom_messenger_plugin_iframe_minimized, #zoom_messenger_plugin_iframe_minimized_ctaMessage_flotante{
    bottom: 10px;
}
#zoom_messenger_plugin_iframe_minimized_encabezado_flotante{
    padding:0;
    text-align:center
}
#zoom_messenger_plugin_iframe_minimized_encabezado_flotante p{
    font-family: sans-serif;
    margin:0;
    margin-top:3px;
    font-size: 17px;
}
#zoom_messenger_plugin_iframe_minimized_encabezado_flotante img{
    /* width:40px; */
    display:initial;
    margin-right:10px;
    max-height: 40px;
    max-width: 65px;
}
#zoom_messenger_plugin_iframe_minimized_ctaMessage_flotante{
    right: 130px;
}

/* ESTILOS PARA LA BURBUJA CLASICA */
#zoom_messenger_plugin_iframe_minimized_classic.zoom_messenger_plugin_iframe_minimized{
    position: fixed!important;
    right: 10px!important;
    background-color:#E6EAEA;
    padding: 10px;
    z-index:100005!important;
    transition: 1s;
    border: 1px solid #d0d0d0!important;
    width:70px;    
    height:60px;
    margin: 0px;
    overflow: hidden;
    margin:0!important;
    padding:5px 0!important;
    border-radius: 50%;
    cursor:pointer;
}
#zoom_messenger_plugin_iframe_minimized_classic.zoom_messenger_plugin_iframe_minimized, #zoom_messenger_plugin_iframe_minimized_ctaMessage_classic{
    bottom: 15px;
}
#zoom_messenger_plugin_iframe_minimized_encabezado_classic{
    padding:0;
    text-align:center
}
#zoom_messenger_plugin_iframe_minimized_encabezado_classic p{
    font-family: sans-serif;
    margin:0;
    /* margin-top:3px; */
    font-size: 17px;
}
#zoom_messenger_plugin_iframe_btnClassic{
    font-size: 41px;
    position: absolute;
    left: 0;
    right: 0;
    top: 48%;
    line-height:0;
}
#zoom_messenger_plugin_iframe_minimized_encabezado_classic p span{
    visibility: hidden;
}
#zoom_messenger_plugin_iframe_minimized_encabezado_classic img{
    /* width:40px; */
    display:initial;
    margin-right:10px;
    max-height: 40px;
    max-width: 65px;
}
#zoom_messenger_plugin_iframe_minimized_ctaMessage_classic{
    right: 96px;
}


/* Estilos del Loader al enviar el formulario */
.enviando{ border: 5px solid #f3f3f3!important; border-radius: 50%!important; border-top: 5px solid red!important; width: 40px!important; height: 40px;
	-webkit-animation: spin 2s linear infinite; /* <- Safari */ animation: spin 2s linear infinite;
}
/* Safari -> */@-webkit-keyframes spin { 0% { -webkit-transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); }}
@keyframes spin { 0% { transform:  rotate(0deg); } 100% { transform:  rotate(360deg); }
}

/* Estilos del Loader al enviar el formulario */
.spinBorders{ -webkit-animation: spinBorders 1s linear infinite; /* <- Safari */ animation: spinBorders 1s linear infinite;
}
/* Safari -> */@-webkit-keyframes spinBorders { 0% { -webkit-transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); }}
@keyframes spinBorders { 0% { box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1); } 100% { box-shadow: 0px 0 0 12px rgba(0, 0, 0, 0); }
}

/* Estilos del Loader al enviar el formulario */
.spinBordersClassic{ -webkit-animation: spinBordersClassic 1s linear infinite; /* <- Safari */ animation: spinBordersClassic 1s linear infinite;
}
/* Safari -> */@-webkit-keyframes spinBordersClassic { 0% { -webkit-transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); }}
@keyframes spinBordersClassic { 0% { box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1); } 100% { box-shadow: 0px 0 0 12px rgba(0, 0, 0, 0); }
}

/* Estilos Panel de administracion del panel */
.zoom-panel-admin{background-color:#dfdfdf8a;padding:25px 10px 25px 10px}
.zoom-panel-admin p{font-size:1rem;}
.zoom-panel-admin > .row{margin:0}
#zoom-panel-admin-modal-accept-message .dashicons-before:before, button[name="zoom_panel_table_add_option"] .dashicons-before:before {line-height: 1.3;}
#zoom_panel{background-color:white; margin-top: 25px; border-radius: 5px;}
.zoom_panel_menu{background-color:#000a37; padding: 0!important; border-radius: 5px;}
.zoom_panel_menu a{color:white; font-size: 20px}
.zoom-panel-admin > .nav-pills .nav-link.active, .nav-pills .show>.nav-link { color: #090c65; background-color: #e0e0e0;}
.zoom_panel_view{padding:10px 15px}
.zoom-panel-admin h2{color:#090c65}
.zoom_panel_table{border:1px solid #090c65; margin:25px 15px; padding:20px 15px}
.zoom_panel_table > .col-6 > p{font-weight: 600}
.zoom_panel_table > .col-6, .zoom_panel_table > .col-6 > p, .zoom_panel_table > .col-3{margin:auto}
.zoom_panel_table > .col-6 > h1{margin:auto; padding:5px 0}
.zoom_panel_table h1{font-size:18px; font-weight: 400; font-style: oblique}
.zoom_panel_table h5{margin-top:auto; margin-bottom:auto}
#zoom_panel_table_add_option{margin: 10px 20px}
#zoom_panel_table_form_option input{margin:5px}

#zoom_panel_messenger input{width: 80%;}
#zoom_panel_messenger h1{width: 100%;}
#zoom_panel_messenger p{margin-bottom:2px}
#zoom_panel_messenger p.leyenda{font-style:oblique; color:grey; font-size:90%; margin:4px 0px}
#zoom_panel_messenger > .zoom_panel_messenger_button {text-align:center; margin-top:25px}
#zoom_panel_messenger > .zoom_panel_messenger_button button{width:40%; background-color: #090c65; color:white}
#zoom_panel_messenger .help-block, #zoom_panel_messenger .error{color:red; font-style: italic; font-weight: 500;}
#zoom-panel-admin-modal-accept{background-color:#0000009e}
#zoom-panel-admin-modal-accept .btn{width:30%; float: right; background-color: #090c65; color: white; margin:2px 10px 10px 10px}
#zoom_panel_view_errores img{width:100%}
#zoom_panel_view_errores em{color:red; font-size: 15px}
#zoom_panel_view_errores .row{border-bottom:2px solid #090c65; padding:20px 0; margin: 0 30px}
#zoom_panel_view_errores .row div{margin:auto; text-align: justify}

@media(max-width:768px){
    #zoom_panel_view_errores .row{margin: 0}
}

@media screen and (max-width:550px){	
	#formLanding1{ padding: 30px 20px 30px 20px!important; }
    #formLanding1 select{ font-size:9px; }
    #zoom_messenger_plugin_iframe_minimized_flotante.zoom_messenger_plugin_iframe_minimized.active, #zoom_messenger_plugin_iframe_minimized.zoom_messenger_plugin_iframe_minimized.active{
        top: 0 !important;
        bottom:0!important;
        right: 0!important;
        left: 0 !important;
        position: fixed!important;
    }
    #zoom_messenger_plugin_iframe_minimized_classic.zoom_messenger_plugin_iframe_minimized.active, #zoom_messenger_plugin_iframe_minimized.zoom_messenger_plugin_iframe_minimized.active{
        top: 0 !important;
        bottom:0!important;
        right: 0!important;
        left: 0 !important;
        position: fixed!important;
    }
}



