.NotFound__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.NotFound__error-text{
    color: $dark-grey;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 21px;
}

.NotFound__title{
    color: $black;
    font-family: 'BuiltTitlingSemiBoldItalic';
    font-size: 48px;
    letter-spacing: -0.02em;
    margin-bottom: 20px;
}

.NotFound__color-green{
    color: $primary;
}