@import '../../base/mixins';

.landing__our-courses-margin-bottom{
    margin-bottom: 25px !important;
}

.landing__hero{
    margin-top: 80px;
}

.landing__hero-text-shadow{
    background-color: $secondary;
} 

.landing__whatsapp-button{

    position: fixed;
    background-color: $primary;
    border-radius: 50px;
    bottom: 3vh;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $white;
    font-size: 16px;
    font-weight: 600;
    right: 10vw;
    padding: 7px;
    z-index: 99;
    cursor: pointer;
    
}

.landing__whatsapp-button-static{

    position: absolute;
    background-color: $primary;
    border-radius: 50px;
    // left: 50%;
    bottom: 10vh;
    right: 10vw;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $white;
    font-size: 16px;
    font-weight: 600;
    padding: 7px;
    z-index: 99;
    cursor: pointer;

    @media (min-width: 400px){
        bottom: 14vh;
        right: 11vw;
    }

    @media (min-width: 450px){
        bottom: 16vh;
        right: 12vw;
    }
    @media (min-width: 600px) {
        
        bottom: 25vh;
        right: 12vw;    
    }
    
    @media (min-width: 641px) {
        bottom: 7vh;
        // transform: translateY(-35%) translateX(420%);   
        border-radius: 50px; 
        // padding: 8px 20px;
    }
    @media (min-width: 748px) {
        bottom: 20vh;
    }
    @media ( min-width: 768px){
        // transform: translateY(-35%) translateX(95%);    
        // background-color: $primary;
        border-radius: 25px;
        bottom: 5vh;
        padding: 8px 20px;
    }
    @media (min-width: 938px) {
        bottom: 8vh;
    }
    @media (min-width: 964px) {
        bottom: 15vh;
    }
    @media (min-width: 1024px){
        // transform: translateY(-35%) translateX(160%);
        bottom: 2vh;    
        padding: 8px 20px;        
    }
    @media (min-width: 1129px) {
        bottom: 15vh;
    }
    @media (min-width: 1400px){
            // transform: translateY(-35%) translateX(230%);    
    }
}

.landing__hero-text{
    font-family: 'BuiltTitlingSemiBoldItalic';
    color: $white;
    font-size: 14px;

    @include small-phone-min{
        font-size: 15px;
    }

    @include phone-min{
        font-size: 17px;
    }
    
    @include big-phone-min{
        font-size: 24px;
    }

    @include tablet-min{
        font-size: 23px;
    }
    @include large-tablet-min{
        font-size: 28px;
    }
    @include desktop-min{
        font-size: 33px;
    }
    @media (min-width: 1200px){
        font-size: 38px;
    }
    @include big-desktop-min{
        font-size: 42px;
    }
}

.landing__container-hero{
    position: relative;
}

.landing__container-hero-text{
    position: absolute;
    
    left: 22%;
    bottom: -3%;

    @include small-phone-min{
        left: 22%;
        bottom: 2%;
    }

    @include phone-min{
        left: 22%;
        bottom: 6%;
    }
    @include big-phone-min{
        left: 22%;
        bottom: 6%;
    }
    @include tablet-min{
        left: 30%;
        bottom: 6%;
    }
    @include desktop-min{
        left: 30%;
        bottom: 4%;
    }
    @media (min-width: 1200px){
        left: 30%;
        bottom: 6%;
    }

}
.landing__hero-button{
    background-color: $primary;
    border-radius: 25px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $white;
    
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    padding: 4px 14px;
    cursor: pointer;
    @include phone-min{
        padding: 7px 17px;
        font-size: 17px;
    }
    @include big-phone-min{
        padding: 8px 20px;
        font-size: 18px;
    }
    @include tablet-min{    
        padding: 8px 20px;
        font-size: 18px;
    }
}
.landing__hero-button a{
  color: #FFF;
}
.landing__hero-button a:hover{
  color: #FFF;
}
.landing__input-wrapper {
  position: relative;
}
.landing__input-wrapper .search-icon {
  position: absolute;
  top: -3px;
  right: -5px;
  padding: 10px;
  pointer-events: none;
}
.landing__right-addon input {
  padding-right: 30px;
}
.landing__container-hero-button{
    position: absolute;
    
    left: 63%;
    bottom: 16%;

    @include small-phone-min{
        left: 63%;
        bottom: 20%;
    }

    @include phone-min{
        left: 63%;
        bottom: 20%;
    }
    @include big-phone-min{
        left: 62%;
        bottom: 20%;
    }
    @include tablet-min{
        left: 57%;
        bottom: 24%;
    }
    @include large-tablet-min{
        left: 59%;
        bottom: 24%;
    }
    @include desktop-min{
        left: 59%;
        bottom: 24%;
    }
    @media (min-width: 1200px){
        left: 58%;
        bottom: 24%;
    }
}
.landing__promo-button{
    border-radius: 25px;
    font-weight: 600;
    line-height: 22px;
    padding: 4px 14px;
    width: 280px;
    height: 40px;
    cursor: pointer;
    @include phone-min{
        padding: 7px 17px;
        font-size: 17px;
    }
    @include big-phone-min{
        padding: 8px 20px;
        font-size: 18px;
    }
    @include tablet-min{    
        padding: 8px 20px;
        font-size: 18px;
    }
}
.landing__promo-button a{
  color: #FFF;
}
.landing__promo-button a:hover{
  color: #FFF;
}
.landing__container-promo-button{
    position: absolute;
    left: 30%;
    bottom: 10%;
    width: 150px;
    height: 30px;

    @include small-phone-min{
        left: 30%;
        bottom: 10%;
        width: 150px;
        height: 30px;
    }

    @include phone-min{
        left: 30%;
        bottom: 10%;
        width: 200px;
    }
    @include big-phone-min{
        left: 30%;
        bottom: 10%;
    }
    @include tablet-min{
        left: 58%;
        bottom: 18%;
    }
    @include large-tablet-min{
        left: 58%;
        bottom: 18%;
    }
    @include desktop-min{
        left: 60%;
        bottom: 18%;
        width: 200px;
        height: 35px;
    }
    @media (min-width: 1200px){
        left: 62%;
        bottom: 18%;
        width: 300px;
        height: 50px;
    }
}
.landing__hero-small-text{
    color: $white;
    font-weight: 400;
    line-height: 25.6px;
    font-size: 16px;
    @media (min-width: 767px){
        font-size: 19px;
    }

    @media (min-width: 1400px){
        font-size: 20px;
    }
}

@media (max-width: 767px) {
 

    .landing__container-unete-a-nosotros{
        background-image: url('../../../assets/images/manos-laptop-small.jpg');
        height: auto;
        background-size: cover;
    }
    
    .landing__paragraph-unete-a-nosotros{
        font-weight: 600;
        font-size: 21px;
        color: $white;
        line-height: 31px;
    } 
}    


@media (min-width: 620px) {
   
    .landing__container-unete-a-nosotros{
        background-image: url('../../../assets/images/manos-laptop-medium.jpg');
        height: auto;
        background-size: cover;
    }
    
    .landing__paragraph-unete-a-nosotros{
        font-weight: 600;
        font-size: 21px;
        color: $white;
    }
    
}    

@media (min-width: 1400px){
   
    .landing__container-unete-a-nosotros{
        background-image: url('../../../assets/images/manos-laptop.jpg');
        height: auto;
        background-size: cover;
    }   


    .landing__paragraph-unete-a-nosotros{
        font-weight: 600;
        font-size: 21px;
        color: $white;
    }

}    
.landing__promotion-text{
    font-family: $font-montserrat;
    color: $white;
    font-size: 16px;
    font-weight: 900;
    line-height: 22px;
    @media (max-width: 767px){
        display: inline-table;
        height: 30px !important;
    }
}
@media (min-width: 992px){
    .text-lg-end {
        text-align: right !important;
    }
}
.landing__promotionB2BCompanies{
    margin-top: 80px;
    background-color: $headband;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 24px;
}
.landing__promotion-button {
    font-family: $font-montserrat;
    background-color: $primaryb2b;
    border-radius: 18px;
    color: $black;
    font-size: 16px;
    font-weight: 900;
    line-height: 22px;
    padding: 10px 22px!important;
    cursor: pointer;
}
.landing__promotion-button:hover {
    color: $white;
}
.landing__bestCourseSection{
    margin-top: 80px;
    background-color: $light-grey;
}
.landing__CarouselHomeSection{
    padding: 0;
    margin: 90px 0 0 0;
    background-color: $light-grey;
}
.carousel-top {
    width: 100%;
    white-space: nowrap;
    position: relative;
  }
  
  .carousel-top-message {
    display: inline-block;
    width: 100%;
    animation: slide 10s infinite;
  }

  @keyframes slide {
    0% { transform: translateX(0%); }
    45% { transform: translateX(0%); }
    50% { transform: translateX(-100%); }
    95% { transform: translateX(-100%); }
    100% { transform: translateX(0%); }
  }
  
  
 /* @keyframes slide {
    0% { transform: translateX(0%); }
    33% { transform: translateX(0%); }
    38% { transform: translateX(-100%); }
    71% { transform: translateX(-100%); }
    76% { transform: translateX(-200%); }
    100% { transform: translateX(-200%); }
  }*/
  
@media (max-width: 769px) {
    .landing__promotionB2BCompanies{
        padding: 5px 5px 20px 10px;
    }
    .landing__promotion-text{
        font-size: 12px;
    }
    .landing__promotion-button {
        font-size: 12px;
    }
}
.CarouselHome__image{
    width: 100%;
    height: auto;
}
.landing__container-promocarousel{
    position: absolute;
    width: 100%;
    height: 100%;
}
.landing__promocarousel{
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.landing__promocarousel a{
  color: #FFF;
}
.landing__promocarousel a:hover{
  color: #FFF;
}

.landing__button-primary{      
    background-color: $primary;
    border-radius: 25px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $white;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    padding: 8px 20px;
    cursor: pointer;
}
.landing__button-primary:hover{
  color: $white;
}

.landing__container{
    margin-right: auto;
    margin-left: auto;
}    

.landing__h3{    
    // font-size: calc(1.3rem + .6vw);
    font-size: 36px;
    margin-top: 0;
    margin-bottom: 0.6rem;
    line-height: 130%;
    display: block;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}    

.landing__icon-por-que-ean{
    height: 100px;
    width: 100px;
    margin: 10px;
}
.landing__icon-por-que-ean:hover{
  -webkit-transform:scale(1.25);
  -moz-transform:scale(1.25);
  -ms-transform:scale(1.25);
  -o-transform:scale(1.25);
  transform:scale(1.25);
}

.landing__paragraph-text{
    font-size: 16px;
    line-height: 160%;
}    
.landing__eanistas-text{
    font-size: 17px;
    line-height: 120%;
    text-align: left;
}
.buttom-graduados-primary{      
    background-color: $buttom-graduados;
    border-radius: 15px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $white;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    padding: 8px 20px;
    cursor: pointer;
}
.landing__button-primary:hover{
  color: $white;
}
.buttom-eanistas-primary{      
    background-color: $buttom-eanistas;
    border-radius: 15px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $black;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    padding: 8px 20px;
    cursor: pointer;
}
.landing__button-primary:hover{
  color: $white;
}  

.landing__container-eres-comunidad-eanista{
    padding: 20px 0px;
    background-color: $banner-eanistas;
}

.landing__paragraph-por-que-ean{
    color: $dark-grey;
    font-size: 16px;
    font-weight: 400;
}    

.landing__section-line{
    color: $light-green;
    border: 3px solid $light-green;
    width: 90px;
    opacity: 100%!important;
    margin: auto;
    margin-top: 5rem;
}    

.landing__title-por-que-ean{
    color: $black;
    font-size: 21px;
    font-weight: 600;
}    
.landing__title-unete-a-nosotros{
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 100%;
    color: $white;
    font-family: 'BuiltTitlingSemiBoldItalic';
    @include tablet-min {
        font-size: 3.8rem;
    }
    @include large-tablet-min {
        font-size: 4.2rem;
    }
    @media (min-width: 1400px){
        // font-size: .82px;
        font-size: 4.8rem;
    }
}

.landing__button-all-courses{
    background-color: $primary;
    border-radius: 25px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $white;
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
    cursor: pointer;
}

.landing__button-see-our-plans{
    background-color: $primary;
    border-radius: 25px;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $white;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 24px;
    cursor: pointer;
}

.landing__text-color-white{
    color: $white;
}
.landing__footer-1{
    background-color: #2E2E2E;
}

.landing__footer-2{
    background-color: $black;
}


.landing__text-color-green{
    color: $primary;
}
.landing__text-color-dark-grey{
    color: $dark-grey;
}
.border-left{
    border-right: 2px solid $buttom-graduados;
}
.landing__text-eres-comunidad-eanista{
    font-weight: 700;
    font-size: 25px;
    text-align: left;
    margin: 14px 0;
    line-height: 28px;
}
.carousel-root {
  outline: none;
  margin-bottom: 20px;
}
.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: visible !important;
}
.carousel .control-dots {
  position: absolute;
  top: -5% !important;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
  height: 10px;
}
.modal-base {
    background-color: #F9FAF6;
    border-radius: 10px;
    font-weight: 600;
    left: 50%;
    line-height: 120%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    font-size: 18px;
    max-width: 500px;
    max-height: 700px;
}
.button-modal{
    display: block;
    border: none;
    cursor: pointer;
    display: block;
    height: 40px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    z-index: 2;
    background: 0;
    color: #000;
  }
.modal-base img{
    border-radius: 5px;
    width: 100%;
    height: 100%;
}
@media (max-width: 940px){
    .modal-base {
        font-size: 18px;
        max-height: 250px;
        min-width: 300px;
        width: 80%;
        border-radius: 4px;
    }
}