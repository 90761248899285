.plan__button-free{
    background-color: $greenpay;
    border-radius: 25px;
    border: 2px solid $greenpay;

    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
}

.plan__button-pay{
    background-color: $blackpay;
    border-radius: 25px;
    border: 2px solid $blackpay;
    box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
}

.plan__col{
    margin: 0px 10px;
}

.plan__container{
    border: 1px solid;
    border-color: $dark-grey;
    border-radius: 40px;
    position: relative;
    min-height: 750px;
}

.plan__container-height{
    height: 720px;
    @include small-phone-min {
        min-height: 800px;
    }
    @include phone-min{
      min-height: 740px;
    }
    @media (min-width: 576px) {
      min-height: 920px;
    }
    @include big-phone-min {
      min-height: 920px;
    }
    @include tablet-min {
      min-height: 735px;
    }
    @include large-tablet-min {
      min-height: 720px;
    }
    @media (min-width: 992px) {
      min-height: 790px;
    }
    @include desktop-min {
      min-height: 700px;
    }
    @include big-desktop-min {
        min-height: 650px;
    }
}

.plan__container-height-recommended{
  height: 720px;
  @include small-phone-min {
    min-height: 840px;
  }
  @include phone-min{
    min-height: 740px;
  }
  @media (min-width: 576px) {
    min-height: 920px;
  }
  @include big-phone-min {
    min-height: 920px;
  }
  @include tablet-min {
    min-height: 735px;
  }
  @include large-tablet-min {
    min-height: 720px;
  }
  @media (min-width: 992px) {
    min-height: 840px;
  }
  @include desktop-min {
    min-height: 750px;
  }
  @include big-desktop-min {
    min-height: 700px;
  }
}

.plan__container-width{
    width: 1240px;
    padding-top: 10px;
    padding-bottom: 30px;
    place-items: flex-end;
}
.plan__container-width-free{
  width: 1240px;
  padding-top: 30px;
  place-items: flex-end;
}
.plan__container-width-middle{
  min-width: 600px;
  max-width: 800px;
  padding-top: 30px;
  place-items: flex-end;
}
.plan__color-active{
    background-color: $light-grey;
    box-shadow: 0px 171px 68px rgba(50, 57, 31, 0.01), 0px 96px 58px rgba(50, 57, 31, 0.05), 0px 43px 43px rgba(50, 57, 31, 0.09), 0px 11px 23px rgba(50, 57, 31, 0.1), 0px 0px 0px rgba(50, 57, 31, 0.1);
    border-radius: 40px;
}
.plan__description{
    color: $black;
    font-size: 16px;
}

.plan__duration{
    color: $dark-grey;
    font-size: 0.85rem;
    font-weight: 700;
}

.plan__height-300{
    height: 300px;
}

.plan__h3{    
    font-size: 36px;
    margin-top: 0;
    margin-bottom: 0.5rem;
    line-height: 130%;
    display: block;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    @media (max-width: 990px){
      margin-bottom: 2rem!important;
    }
}    

.plan__items-text{
    color: $blackpay;
    font-size: 16px;
    margin-left: 13px;
    @media (min-width:992px) {
        margin-left: 10px;
        
    }
    @media (min-width:1104px) {
        margin-left: 15px;
    }
}
.plan__icon-check{
    color: $secondary;
    font-size: 20px;
}
.plan__icon-xmark{
    color: $dark-grey;
    font-size: 20px;
}
.plan__name{
    color: $blackpay;
    font-size: 21px;
    font-weight: 600;
    margin: 0.3em auto 0 auto;
}
.plan__price{
    color: $blackpay;
    font-size: 30px;
    font-weight: 900;
}

.plan__section-line{
    color: $light-green;
    border: 3px solid $light-green;
    width: 90px;
    opacity: 100%!important;
    margin: auto;
    margin-top: 5rem;
}    
.plan__suggested{
    background-color: $primary;
    border-radius: 0px 0px 10px 10px;
    height: 34px;
    position: absolute;
    width: 60%;
    top: -1px;
    right: 23%;
    @media (max-width:395px) {
      width: 80%;
      right: 16%;
    }
    @media (max-width:395px) and (max-width:768px) {
      font-size: 0.8em;
    }
}
.plan__suggested-text{
    color: $white;
    font-size: 0.99em;
    font-weight: 600;
    line-height: 20.4px;
    margin-top: 6px;
    display: block;
    @media (max-width:768px) {
      font-size: 0.8em;
    }
}

@keyframes jump{
  0%{ transform:translateY(0); }
  6%{ transform:translateY(0); }
  12%{ transform:translateY(-8px); }
  18%{ transform:translateY(0); }
  24%{ transform:translateY(-4px); }
  30%{ transform:translateY(0); }
  100%{ transform:translateY(0); }
  }


.plan__suggested-icon {
  animation:jump 3s ease-out 0s infinite normal;
  vertical-align: baseline !important;
}

.plan__text-container{
    // background-color: $white;
    min-height: 290px;
    height: 395px;
    @include small-phone-min {
        min-height: 380px;
    }
    @include phone-min{
      min-height: 350px;
    }
    @media (min-width: 576px) {
      min-height: 500px;
    }
    @include big-phone-min {
      min-height: 540px;
    }
    @include tablet-min {
      min-height: 350px;
    }
    @include large-tablet-min {
      min-height: 320px;
    }
    @media (min-width: 992px) {
      min-height: 420px;
    }
    @include desktop-min {
      min-height: 370px;
    }
    @include big-desktop-min {
      min-height: 290px;
    }
}
.h-full {
  min-height: 90vh;
}
.video-container {
  padding-bottom: 31.5%;
  @media (max-width: 769px) { 
    padding-bottom: calc((574 / 543) * 100%);
  }
}

.plan__text-container__recommended{
    // background-color: $white;
    height: 344px;
    @include small-phone-min {
      min-height: 380px;
    }
    @include phone-min{
      min-height: 390px;
    }
    @media (min-width: 576px) {
      min-height: 500px;
    }
    @include big-phone-min {
      min-height: 540px;
    }
    @include tablet-min {
      min-height: 390px;
    }
    @include large-tablet-min {
      min-height: 320px;
    }
    @media (min-width: 992px) {
      min-height: 470px;
    }
    @include desktop-min {
      min-height: 390px;
    }
    @include big-desktop-min {
      min-height: 390px;
    }
}

.plan__total-price{
    color: $black;
    font-weight: 600;
    margin-top: 0.8em;
    margin-bottom: 0.5em;
}

@keyframes remarkBorders { 
  0% { box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2); } 
  100% { box-shadow: 0px 0 0 25px rgba(0, 0, 0, 0); }
}

.plan__border{
    border: 3px solid #3BAC53;
    animation: remarkBorders 1s linear infinite;
  }

  .plan__buttonFreePlan {
  align-self: center;
}
@media (max-width: 992px) {
  .widthbenefits {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .widthbenefits {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .plan__container-benefits {
    display: flex !important;
    flex-direction: row;
  }
  .plan__unique-benefits {
    width: 100%;
  }
  @for $order from 1 through 10 {
    .plan__benefits-order-lg-#{$order} {
      order: $order
    }   
  }
}
.carousel .slider-wrapper {
  overflow: visible !important;
  margin: 0 !important;
  width: 100%;
  transition: height .15s ease-in;
}