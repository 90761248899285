
.footer__text-color-white{
    color: $white;
}
.footer__footer-1{
    background-color: #2E2E2E;
}

.footer__footer-2{
    background-color: $black;
}

.footer__text-color-green{
    color: $primary;
}
.footer__text-color-dark-grey{
    color: $dark-grey;
}
.footer__text-rights{
    font-family: 'Open Sans';
    font-weight: 600;
    color: $greynew;
}
